import React from 'react';
import './PartnersSection.css'; // Styles for animation
import partner2 from '../../assests/company/2.png';

import { useSelector } from 'react-redux';

const PartnersSection = () => {
    const partners = [
        partner2
    ];

    const firstRow = partners.slice(0, 7);
    const lang = useSelector((state) => state.lang.lang);

    return (
        <section className="partners-section ">
          <div className="header1-l1">
                        <p className='card-title between-borders1-l1'>      
                            {lang === 'en' ? 'Partners of Success' : 'شركاء النجاح '}

                        </p>
                    </div>
            <div className="partner-row partner-row-1 ">
                {firstRow.map((partner, index) => (
                    <img key={index} src={partner} alt={`Partner ${index + 1}`} className="partner-logo" />
                ))}
            
            </div>
            
        </section>
    );
};

export default PartnersSection;